.product-form .ant-legacy-form-item {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.product-form .ant-input-group {
  width: auto;
}

.product-form .product-price-input input {
  width: 120px;
}
.product-form .product-price-part-input input {
  width: 130px;
}

.form_item label {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  color: rgba(0, 0, 0, 0.55) !important;
}

label {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
}

.arcsite-form-item-with-subtitle .ant-form-item-label {
  margin-bottom: 0px;
  padding-bottom: 0px !important;
}

.arcsite-form-item-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  margin-bottom: 8px;
}

.field_detail_section {
  margin-bottom: 15px;
}

.as-active-box {
  box-shadow: 0 0 5px #1b98e0 !important;
}

.as-form-field-required::before {
  display: inline-block;
  margin-right: 4px;
  color: red;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

label.as-form-field-label {
  margin-bottom: 5px !important;
  color: rgba(0, 0, 0, 0.55);
  font-size: 12px;
  text-transform: none;
}

.as-box-hover-shadow:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.5s ease-in-out;
}

#archiving-tasks-app {
  width: 300px;
  z-index: 9999;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  bottom: 0;
  right: 0;
  position: fixed;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  border-radius: 0.25rem;
  background-color: #fff;
}

.arcsite-confirm-modal .ant-modal-body {
  padding: 0;
}
.arcsite-confirm-modal .ant-modal-confirm-title {
  /* padding: 16px 0px; */
  /* padding-bottom: 16px; */
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  /* border-bottom: 1px solid #e8e8e8; */
}

.arcsite-confirm-modal .ant-modal-confirm-btns {
  padding: 10px 16px;
  margin-top: 0;
}
/* .arcsite-confirm-modal .ant-modal-confirm-content {
  padding: 24px;
  border-bottom: 1px solid #e8e8e8;
} */

.visual-form-item .ant-legacy-form-explain {
  white-space: nowrap;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}
.editable-cell-value-wrap:hover {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-table-cell .editable-cell-value-disabled:hover {
  border: none;
}
